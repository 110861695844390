<template>
  <content-items-list
    content-type="events"
    content-type-singular="event"
    content-name="Events"
    content-name-singular="Events"
  />
</template>

<script>
import ContentItemsList from '@/views/content-item/content-items-list/ContentItemsList.vue'

export default {
  components: {
    ContentItemsList,
  },
}
</script>

<style scoped>

</style>
